import { Component, ViewChild, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { LotModel } from '@libs/portal-common/services';
import { GooglePlacesAutocompleteDirective } from '../google-places-autocomplete/google-places-autocomplete.directive';
import { Address } from '../google-places-autocomplete/Address';

@Component({
  selector: 'app-location-address',
  templateUrl: './location-address.component.html',
  styleUrls: ['./location-address.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class LocationAddressComponent implements AfterViewInit {
  @ViewChild('places', { static: true }) places: GooglePlacesAutocompleteDirective;

  options: google.maps.places.AutocompleteOptions = {
    types: ['address'],
    fields: ['place_id', 'address_component', 'geometry'],
  };

  addressGoogleInputValue: string;

  private _editable = true;
  @Input('editable')
  get editable(): boolean {
    return this._editable;
  }
  set editable(val: boolean) {
    this._editable = val;
    this.disableAddressParts();
  }

  private _model: LotModel;
  public _validate = false;
  public streetDisabled = false;
  public cityDisabled = false;
  public stateDisabled = false;
  public zipDisabled = false;

  @Input('model')
  get model(): LotModel {
    return this._model;
  }
  set model(val: LotModel) {
    this._model = val;
    this.places.reset(this.addressString(val));
    this.disableAddressParts();
  }

  @Input() set validate(flag: boolean) {
    this._validate = flag;
    let googleAutocomplete = this.places['el'];
    this.addressGoogleInputValue = googleAutocomplete ? googleAutocomplete.nativeElement.value : '';
  }

  @Output() addressChanged = new EventEmitter<Address>();
  @Output() addressGoogleInputIsEmpty = new EventEmitter<boolean>();

  ngAfterViewInit() {
    this.places.reset(this.addressString(this._model));
  }

  addressGoogleInputChange(event: any) {
    if (event && event?.target) {
      this.addressGoogleInputValue = event.target.value;
      if (event.target.value.length === 0) {
        this.addressGoogleInputIsEmpty.emit(true);
      } else {
        this.addressGoogleInputIsEmpty.emit(false);
      }
    }
  }

  public onPlaceChange(address: Address) {
    if (!!address) {
      this.model.City = address.city;
      this.model.Street = address.street;
      this.model.UState = address.state;
      this.model.Zip = address.zip;
    }

    this.disableAddressParts();
    this.addressChanged.emit(address);
  }

  private addressString(model: LotModel): string {
    if (!model) {
      return null;
    }

    const addrParts = [model.Street, model.City, model.UState];

    return addrParts.filter((x) => !!x).join(', ');
  }

  private disableAddressParts() {
    if (!this.model) {
      return;
    }

    this.streetDisabled = !this.editable || !!this.model.Street;
    this.cityDisabled = !this.editable || !!this.model.City;
    this.stateDisabled = !this.editable || !!this.model.UState;
    this.zipDisabled = !this.editable || !!this.model.Zip;
  }
}
