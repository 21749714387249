import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { AppNotificationsService } from '@libs/portal-common/system';
import { DateToString } from './extensions/date-to-string';

export class ReportRequest {
  Name: string;
  Format: string;
  Parameters: string;
  DataProcess?: any;
}

export class ReportResponse {
  Data: any;
  Success: boolean;
  Message: string;
  ErrorCode: string;
}

export class LongReportResult {
  Blob: Blob;
  IsProcessed: boolean;
  Email: string;
}

class LongReportResponseBody {
  Email: string;
}

@Injectable({ providedIn: 'root' })
export class ReportsService {
  constructor(
    private _httpClient: HttpClient,
    private notifications: AppNotificationsService,
  ) {}

  getCallHistory(parameters: any, state: any = null): Observable<any> {
    return this.getReport('CallHistory', parameters, state);
  }

  getIncidents(parameters: any): Observable<any> {
    return this.getReport('Incidents', parameters);
  }

  getHeatmap(parameters: any): Observable<any> {
    return this.getReport('Heatmap', parameters);
  }

  getCallStats(parameters: any): Observable<any> {
    return this.getReport('CallStats', parameters);
  }

  getTopLanesIssues(parameters: any): Observable<any> {
    return this.getReport('TopLanesIssues', parameters);
  }

  getTopIssuesByType(parameters: any): Observable<any> {
    return this.getReport('TopIssuesByType', parameters);
  }

  getDashboard(parameters: any): Observable<any> {
    return this.getReport('Dashboard', parameters);
  }

  getAgentMetrics(parameters: any): Observable<any> {
    return this.getReport('AgentMetrics', parameters);
  }

  getIncidentsCsv(parameters: any): Observable<Blob> {
    return this.getCsvReport('IncidentsCsv', parameters).pipe(
      map((result) => {
        return result.Blob;
      }),
    );
  }

  getLocations(parameters: any): Observable<Blob> {
    return this.getCsvReport('Locations', parameters).pipe(
      map((result) => {
        return result.Blob;
      }),
    );
  }

  getCreatedLanesWithVoicePlatformDevices(parameters: any): Observable<LongReportResult> {
    return this.getCsvReport('CreatedLanesWithVoicePlatformDevices', parameters).pipe(
      map((result) => {
        return result;
      }),
    );
  }

  private getReport(reportName: string, parameters: any, state: any = {}): Observable<any> {
    let url = `Reports/${reportName}`;

    let request = JSON.parse(JSON.stringify(state));
    request.parameters = JSON.parse(JSON.stringify(parameters));

    if (!!request.parameters && request.parameters.Start instanceof Date) {
      request.parameters.Start = DateToString(request.parameters.Start);
    }
    if (!!request.parameters && request.parameters.End instanceof Date) {
      request.parameters.End = DateToString(request.parameters.End);
    }

    return this._httpClient.post<ReportResponse>(url, request).pipe(
      map((response) => {
        if (!response.Success) {
          this.notifications.error(response.Message);

          return [];
        }
        return response.Data;
      }),
    );
  }

  private getCsvReport(reportName: string, parameters: any, state: any = {}): Observable<LongReportResult> {
    let url = `Reports/${reportName}`;

    let request = JSON.parse(JSON.stringify(state));
    request.parameters = JSON.parse(JSON.stringify(parameters));

    if (!!request.parameters && request.parameters.Start instanceof Date) {
      request.parameters.Start = DateToString(request.parameters.Start);
    }
    if (!!request.parameters && request.parameters.End instanceof Date) {
      request.parameters.End = DateToString(request.parameters.End);
    }

    return this._httpClient.post(url, request, { observe: 'response', responseType: 'blob' }).pipe(
      map((data) => {
        if (data.status == 200) {
          return { Blob: data.body as Blob, IsProcessed: true, Email: null };
        } else if (data.status == 202) {
          var email = data.headers.get('X-Target-Email');
          return { Blob: null, IsProcessed: false, Email: email };
        } else {
          throw new Error('CSV report getting is failed.');
        }
      }),
    );
  }
}
