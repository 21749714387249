<div class="form-group col-sm-12">
  <label>Address<sup class='required-star'></sup></label>
  <input
    type="text"
    id='addressGoogleInput'
    class="form-control address"
    appGooglePlacesAutocomplete
    #places="appGooglePlacesAutocomplete"
    name="address"
    (change)='addressGoogleInputChange($event)'
    (addressChange)="onPlaceChange($event)"
    [options]="options"
  />
  <div class='text-danger' *ngIf='_validate && !addressGoogleInputValue'>The field is required</div>
</div>

<div *ngIf="!!model" class="address-fields">
  <div class="row">
    <div class="form-group col-sm-6" [class.has-error]="state | hasErrors: _validate">
      <label for="state">State<sup class='required-star'></sup></label>
      <input
        type="text"
        name="state"
        class="form-control"
        [(ngModel)]="model.UState"
        #state="ngModel"
        [disabled]="stateDisabled"
        required
      />
      <app-validation-errors [model]="state" [validate]="_validate"></app-validation-errors>
    </div>

    <div class="form-group col-sm-6" [class.has-error]="street | hasErrors: _validate">
      <label for="street">Street<sup class='required-star'></sup></label>
      <input
        type="text"
        name="street"
        class="form-control"
        [(ngModel)]="model.Street"
        #street="ngModel"
        [disabled]="streetDisabled"
        required
      />
      <app-validation-errors [model]="street" [validate]="_validate"></app-validation-errors>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-sm-6" [class.has-error]="city | hasErrors: _validate">
      <label for="city">City<sup class='required-star'></sup></label>
      <input type="text" name="city" class="form-control" [(ngModel)]="model.City" #city="ngModel" [disabled]="cityDisabled" required />
      <app-validation-errors [model]="city" [validate]="_validate"></app-validation-errors>
    </div>

    <div class="form-group col-sm-6" [class.has-error]="zip | hasErrors: _validate">
      <label for="zip">Zip Code<sup class='required-star'></sup></label>
      <input type="text" name="zip" class="form-control" [(ngModel)]="model.Zip" #zip="ngModel" [disabled]="zipDisabled" required />
      <app-validation-errors [model]="zip" [validate]="_validate"></app-validation-errors>
    </div>
  </div>
</div>
