import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, map, filter, flatMap } from 'rxjs/operators';

import { BaseResponse, IRestBaseListResponse } from '../models/rest/baseResponse';
import { AppNotificationsService } from '@libs/portal-common/system';

export interface IRuleSet {
  Id: number;
  Name: string;
  Color?: string;
  fkRegion: number;
}

export interface ICreateRuleSetModel {
  ruleSet: IRuleSet;
  sourceRuleSetId?: number;
}

export interface IGetRuleSetsResponse extends IRestBaseListResponse<IRuleSet> {}
export interface IGetRuleSetResponse extends BaseResponse {
  RuleSet: IRuleSet;
}
export interface ICreateRuleSetResponse extends BaseResponse {
  RuleSet: IRuleSet;
}
export interface IUpdateRuleSetResponse extends BaseResponse {
  RuleSet: IRuleSet;
}

@Injectable({ providedIn: 'root' })
export class RuleSetService {
  private url = 'RuleSet';

  constructor(
    private _httpClient: HttpClient,
    private notifications: AppNotificationsService,
  ) {}

  getAll(): Observable<IGetRuleSetsResponse> {
    return this._httpClient.get<IGetRuleSetsResponse>(this.url);
  }

  getRuleSet(id: number): Observable<IGetRuleSetResponse> {
    return this._httpClient.get<IGetRuleSetResponse>(`${this.url}/${id}`).pipe(
      tap((response) => {
        this.notifications.checkResponse(response);
      }),
    );
  }

  create(model: ICreateRuleSetModel): Observable<ICreateRuleSetResponse> {
    return this._httpClient.post<ICreateRuleSetResponse>(this.url, model);
  }

  update(model: IRuleSet): Observable<IUpdateRuleSetResponse> {
    return this._httpClient.put<IUpdateRuleSetResponse>(this.url, { RuleSet: model });
  }

  delete(Id: number): Observable<any> {
    return this._httpClient.request<any>('delete', `${this.url}`, {
      body: { Id },
    });
  }
}
