<div class="ibox__title">
  <h3 class="ibox__heading">Incident details</h3>
  <button class="button button--primary create-ticket" *ngIf="!!model && canAddTicket" (click)="addTicket()">
    <i class="fa fa-plus"></i> Create Ticket
  </button>
</div>

<div class="ibox__content">
  <div *ngIf="!busy">
    <table class="table table-hover">
      <thead>
        <tr>
          <th style="width: 40%">Field</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Notes</td>
          <td>{{ model.Notes }}</td>
        </tr>
        <tr>
          <td>Incident ID</td>
          <td>{{ model.Id }}</td>
        </tr>
        <tr>
          <td>Date</td>
          <td>{{ model.CallTime | dateFormat: 'MMM DD HH:mm:ss A' }}</td>
        </tr>
        <tr>
          <td>Lot</td>
          <td>{{ model.LotNumber }}</td>
        </tr>
        <tr>
          <td>Location</td>
          <td>{{ model.LotName }}</td>
        </tr>
        <tr>
          <td>Lane</td>
          <td>{{ model.Lane }}</td>
        </tr>
        <tr>
          <td>Issue</td>
          <td>{{ model.Issue }}</td>
        </tr>
        <tr>
          <td>Vend</td>
          <td>
            <p [class.vend-warning]="model.Vend">{{ model.Vend ? 'YES' : 'NO' }}</p>
          </td>
        </tr>
        <tr>
          <td>Voice Bot</td>
          <td>
            <p [class.vend-warning]="model.VBEventId">{{ model.VBEventId ? 'YES' : 'NO' }}</p>
          </td>
        </tr>
        <tr>
          <td>VB ConferenceId</td>
          <td>
            <p>{{ model.VBEventConferenceId }}</p>
          </td>
        </tr>
        <tr>
          <td>VB Dur</td>
          <td *ngIf="model.VBEventId" class="time-field" [ngClass]="activityCss.queueFieldClass(model.VBEventDur)">
            {{ model.VBEventDur | duration: 'HH:mm' }}
          </td>
          <td class="time-field" *ngIf="!model.VBEventId">__:__</td>
        </tr>
        <tr>
          <td>VB Rec.</td>
          <td>
            <button
              class="button button--tiny button--secondary"
              (click)="showVBEventRecords(model.VBEventConferenceId)"
              *ngIf="model.VBEventFileName"
            >
              <i class="fa fa-music"></i>
            </button>
          </td>
        </tr>
        <tr>
          <td>Transcript</td>
          <td>
            <button
              class="button button--tiny button--secondary"
              (click)="showTranscript(model.Id, model.VBEventConferenceId)"
              *ngIf="model.VBEventCallSegment"
            >
              <i class="fa fa-file-text"></i>
            </button>
          </td>
        </tr>
        <tr>
          <td>Queue</td>
          <td class="time-field" [ngClass]="activityCss.queueFieldClass(model.QueueTime)">
            {{ model.QueueTime | duration: 'HH:mm' }}
          </td>
        </tr>
        <tr>
          <td>Duration</td>
          <td class="time-field" [ngClass]="activityCss.durationFieldClass(model.CallDuration)">
            {{ model.CallDuration | duration: 'HH:mm' }}
          </td>
        </tr>
        <tr>
          <td>Wrapup</td>
          <td class="time-field" [ngClass]="activityCss.wrapupFieldClass(model.Wrapup)">
            {{ model.Wrapup | duration: 'HH:mm' }}
          </td>
        </tr>
        <tr>
          <td>Total</td>
          <td class="time-field" [ngClass]="activityCss.totalFieldClass(model.TotalTime)">
            {{ model.TotalTime | duration: 'HH:mm' }}
          </td>
        </tr>
        <tr>
          <td>Rec.</td>
          <td>
            <button class="button button--tiny button--secondary" (click)="showRecords(model.Id)" *ngIf="model.IsRecordingFound">
              <i class="fa fa-music"></i>
            </button>
          </td>
        </tr>
        <tr>
          <td>Video</td>
          <td>
            <button class="button button--tiny button--secondary" (click)="showVideoRecord(model.Id)" *ngIf="model.IsVideoRecordingFound">
              <i class="fa fa-play"></i>
            </button>
          </td>
        </tr>
        <tr>
          <td>Station ID</td>
          <td>
            {{ model.NoodoeStationId }}
          </td>
        </tr>
        <tr>
          <td>Start Charging</td>
          <td>
            <p [class.vend-warning]="model.NoodoeStationChargeStarted">{{ model.NoodoeStationChargeStarted ? 'YES' : 'NO' }}</p>
          </td>
        </tr>
        <tr>
          <td>Stop Charging</td>
          <td>
            <p [class.vend-warning]="model.NoodoeStationChargeStopped">{{ model.NoodoeStationChargeStopped ? 'YES' : 'NO' }}</p>
          </td>
        </tr>
        <tr>
          <td>Soft Reset</td>
          <td>
            <p [class.vend-warning]="model.NoodoeStationReseted">{{ model.NoodoeStationReseted ? 'YES' : 'NO' }}</p>
          </td>
        </tr>
        <tr>
          <td>Maintenance Mode</td>
          <td>
            {{ model.NoodoeStationMaintenaceModeLastAction }}
          </td>
        </tr>
        <tr>
          <td>Agent</td>
          <td>{{ model.Agent }}</td>
        </tr>
        <tr>
          <td>Call is closed by Agent</td>
          <td>
            <p>{{ model.IsCallClosedByAgent ? 'YES' : 'NO' }}</p>
          </td>
        </tr>
        <tr *ngIf="!!model.Parker">
          <td>Parker</td>
          <td>{{ model.Parker }}</td>
        </tr>
        <tr *ngIf="!!model.CallMatchingGroupName">
          <td>Group</td>
          <td>{{ model.CallMatchingGroupName }}</td>
        </tr>
        <tr *ngFor="let item of staticFields">
          <td>{{ item.Name }}</td>
          <td>{{ item.Value }}</td>
        </tr>
        <tr *ngFor="let item of dynamicFields">
          <td>{{ item.Name }}</td>
          <td>{{ item.Value }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<app-audio-records #audioRecords></app-audio-records>
<app-video-record #videoRecord></app-video-record>
<app-create-ticket #createTicket></app-create-ticket>
<app-voicebot-transcription #voiceBotTranscription></app-voicebot-transcription>
