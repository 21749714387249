import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-field-view',
  templateUrl: './field-view.component.html',
  styleUrls: ['./field-view.component.scss'],
})
export class FieldViewComponent {
  @Input('fieldValue') fieldValue: any;
  constructor() {
  }
}
