<div class="row">
  <div class="col-sm-12" *ngIf="ticket">
    <div class="ibox">
      <div class="ibox__title">
        <h3 class="ibox__heading">{{ title }}</h3>
        <div class="ibox__tools">
          <button *ngIf="ticket.IsSubscribed" class="button button--warning" (click)="unsubscribe()"><i>Unsubscribe</i></button>
          <button *ngIf="!ticket.IsSubscribed" class="button button--secondary" (click)="subscribe()"><i>Subscribe</i></button>
          <button *ngIf="!editing && isOwner" class="button button--primary" (click)="showEdit()"><i class="fa fa-pencil"></i> Edit</button>
          <button *ngIf="editing && isOwner" class="button button--primary" (click)="saveChanges()">
            <i class="fa fa-save"></i> Save changes
          </button>
          <button *ngIf="editing && isOwner" class="button button--margin-left" (click)="cancelEdit()">
            <i class="fa fa-disk"></i> Cancel
          </button>
        </div>
      </div>
      <div class="ibox__content">
        <div *ngIf="!editing">
          <div class="form-group row">
            <label class="col-sm-2 col-form-label" for="title">ID</label>
            <span>{{ ticket.Id }}</span>
          </div>
          <div class="form-group row">
            <label class="col-sm-2" for="title">Title</label>
            <span>{{ ticket.Title }}</span>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label" for="description">Description</label>
            <span>{{ ticket.Description }}</span>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label" for="description">Status</label>
            <span>{{ ticket.Status }}</span>
          </div>
          <div class="form-group row" *ngIf="ticket.LocationId">
            <label class="col-sm-2 col-form-label" for="description">Location</label>
            <span>{{ ticket.LocationNumber }} - {{ ticket.Location }}</span>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label" for="title">Creator</label>
            <span>{{ ticket.Name }}</span>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label" for="title">Created</label>
            <span>{{ ticket.Created | dateFormat: 'MM/DD/YYYY HH:mm:ss A' }}</span>
          </div>
          <div class="form-group row" *ngIf="ticket.IncidentId">
            <label class="col-sm-2 col-form-label">Incident</label>
            <a [routerLink]="['/activity/daily/' + ticket.IncidentId + '/details']">{{ ticket.IncidentId }}</a>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label" for="title">Incident notes</label>
            <div class="col-sm-10" style="padding-left: 0">
              {{ ticket.MergedFields }}
            </div>
          </div>
        </div>
        <!-- Editing forms -->
        <form #form="ngForm" novalidate *ngIf="editing">
          <div class="form-group row">
            <label class="col-sm-2 col-form-label" for="title">ID</label>
            <div class="col-sm-4">
              <span>{{ ticket.Id }}</span>
            </div>
          </div>
          <div class="form-group row">
            <label for="title" class="col-sm-2 col-form-label">Title</label>
            <div class="col-sm-4">
              <input style="height: 25px" type="text" name="title" class="form-control" [(ngModel)]="ticket.Title" />
            </div>
          </div>
          <div class="form-group row">
            <label for="description" class="col-sm-2 col-form-label">Description</label>
            <div class="col-sm-4">
              <input style="height: 25px" type="text" name="description" class="form-control" [(ngModel)]="ticket.Description" />
            </div>
          </div>
          <div class="form-group row">
            <label for="status" class="col-sm-2 col-form-label">Status</label>
            <div style="height: 25px" class="col-sm-4">
              <app-dropdown
                name="status"
                [data]="ticketStatuses"
                [(ngModel)]="ticket.StatusId"
                [textField]="'Status'"
                [valueField]="'Id'"
                #statusId="ngModel"
              >
              </app-dropdown>
            </div>
          </div>

          <div class="form-group row">
            <label for="location" class="col-sm-2 col-form-label">Location</label>
            <div style="height: 25px" class="col-sm-4">
              <app-dropdown
                name="location"
                [data]="locations"
                [(ngModel)]="ticket.LocationId"
                [textField]="'LotName'"
                [valueField]="'Id'"
                #locationId="ngModel"
              >
              </app-dropdown>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label" for="title">Creator</label>
            <div class="col-sm-4">
              <span>{{ ticket.Name }}</span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label" for="title">Created</label>
            <div class="col-sm-4">
              <span>{{ ticket.Created | dateFormat: 'MM/DD/YYYY HH:mm:ss A' }}</span>
            </div>
          </div>

          <div class="form-group row" *ngIf="ticket.IncidentId">
            <label class="col-sm-2 col-form-label">Incident</label>
            <div class="col-sm-4">
              <a [routerLink]="['/activity/daily/' + ticket.IncidentId + '/details']">{{ ticket.IncidentId }}</a>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label" for="title">Incident notes</label>
            <div class="col-sm-10">
              {{ ticket.MergedFields }}
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="ibox">
      <div class="ibox__title">
        <h3 class="ibox__heading">Comments</h3>
      </div>
      <div class="ibox__content">
        <div class="form-group row" *ngFor="let ticketComment of ticketComments">
          <label class="col-sm-2 col-form-label"
            >{{ ticketComment.AuthorName }}
            <div style="font-weight: initial; font-size: 12px">
              {{ ticketComment.Modified || ticketComment.Created | date: 'dd/MM/yyyy h:mm a' }}
            </div>
          </label>
          <div class="col-sm-5">
            <p class="form-control-static">{{ ticketComment.Comment }}</p>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-2 col-form-label">New Comment</label>
          <div class="col-sm-5">
            <textarea class="form-control" [(ngModel)]="newComment" name="newComment" rows="3"></textarea>
          </div>
          <div class="col-sm-1">
            <button class="button button--primary" (click)="addNewComment()"><i class="fa fa-plus"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
