import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Kendo
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { GridModule, ExcelModule, PDFModule } from '@progress/kendo-angular-grid';
import { SortableModule } from '@progress/kendo-angular-sortable';

import { ModalModule } from 'ngx-bootstrap/modal';

import { SharedModule } from '@libs/portal-common/shared';
import { SystemModule } from '@libs/portal-common/system';
import { ServicesModule } from '@libs/portal-common/services';
import { ValidationModule } from '@libs/portal-common/validation';

import { ActivityRoutingModule } from './activity-routing.module';

import { DailyActivityComponent } from './daily-activity/daily-activity.component';
import { LaneActivityComponent } from './lane-activity/lane-activity.component';
import { AgentActivityComponent } from './agent-activity/agent-activity.component';
import { HeatmapComponent } from './heatmap/heatmap.component';
import { ActivityGridComponent } from './activity-grid/activity-grid.component';
import { ActivityFilterComponent } from './activity-filter/activity-filter.component';
import { AudioRecordsComponent } from './audio-records/audio-records.component';
import { VideoRecordComponent } from './video-record/video-record.component';
import { VoiceBotTranscriptionComponent } from './voicebot/voicebot-transcription.component';
import { HeatmapContainerComponent } from './heatmap/heatmap-container/heatmap-container.component';
import { ActivityItemComponent } from './activity-item/activity-item.component';
import { IncidentDetailsComponent } from './activity-item/incident-details/incident-details.component';
import { IncidentQualityAnalysisComponent } from './activity-item/incident-quality-analysis/incident-quality-analysis.component';

import { CallHistoryService } from './services/call-history.service';
import { QualityAnalysisService } from './services/quality-analysis.service';
import { QualityAnalysisComponent } from './quality-analysis/quality-analysis.component';
import { GridStateService } from './services/grid-state.service';
import { ActivityGridFieldsPickerComponent } from './activity-grid-fields-picker/activity-grid-fields-picker.component';
import { TicketResolverService } from './services/ticket-resolver.service';
import { IncidentTicketComponent } from './activity-item/incident-ticket/incident-ticket.component';
import { ActivityAddNoteComponent } from './activity-add-note/activity-add-note.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    DropDownsModule,
    DateInputsModule,
    GridModule,
    ExcelModule,
    PDFModule,
    SortableModule,

    ModalModule,

    SharedModule,
    SystemModule,
    ServicesModule,
    ValidationModule,

    ActivityRoutingModule,
  ],
  declarations: [
    DailyActivityComponent,
    LaneActivityComponent,
    AgentActivityComponent,
    HeatmapComponent,
    ActivityGridComponent,
    ActivityFilterComponent,
    AudioRecordsComponent,
    VideoRecordComponent,
    HeatmapContainerComponent,
    ActivityItemComponent,
    IncidentDetailsComponent,
    IncidentQualityAnalysisComponent,
    QualityAnalysisComponent,
    ActivityGridFieldsPickerComponent,
    IncidentTicketComponent,
    ActivityAddNoteComponent,
    VoiceBotTranscriptionComponent
  ],
  providers: [CallHistoryService, QualityAnalysisService, GridStateService, TicketResolverService],
  exports: [AgentActivityComponent],
})
export class ActivityModule {}
